import { BaseStepMadeModel } from "./BaseStepMadeModel";

export class ServiceModel extends BaseStepMadeModel {
	constructor() {
		super("service");
	}

	imageUrl: string;
	count: number;
	privacyPolicyId: number;
	typeId: number;
	cmsId: string;
}