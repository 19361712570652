
import { defineStore } from 'pinia'

import { StoreConfigurationModel } from "../Configuration/StoreConfigurationModel";
import { AppointmentItem } from "../Appointment/AppointmentItem";
import { TenantItem } from "../Tenant/TenantItem";
import { SpidItemResponse } from "../Spid/SpidItemResponse";
import { AppointmentByNotificationItem } from "../Appointment/AppointmentByNotificationItem";
import { ParametersProMadeModel } from '../Step/ParametersProMadeModel';
import { ParametersMadeModel } from '../Step/ParametersMadeModel';
import { UserLoggedData } from '../Step/UserLoggedData';

interface State {
	config: StoreConfigurationModel[];
	stepsMade: ParametersMadeModel;
	bookedit: AppointmentItem;
	proStepsMade: ParametersProMadeModel;
	tenantSelected: TenantItem;
	tenants: TenantItem[];
	jwtReAppTotem: string;
	idReAppTotem: string;
	bookingSubmitted: AppointmentItem;
	bookingByNotificationSubmitted: AppointmentByNotificationItem;
	tenantsFilter: string[];
	roomTypeFilter: string[];
	municipalityFilter: string[];
	pageAsGoToHome: string;

	totemLocation: string;
	spidResponse: SpidItemResponse;

	userLoggedData: UserLoggedData;
}

export const useStore = defineStore('main', {
	state: () => ({
		config: null,
		stepsMade: {
			municipality: null,
			location: null,
			service: null,
			month: null,
			day: null,
			interval: null,
			spid: null,
			details: null
		},
		proStepsMade: {
			municipality: null,
			categories: null,
			services: null,
			location: null,
			month: null,
			day: null,
			interval: null
		},
		tenantSelected: null,
		tenants: [],
		bookedit: null,
		jwtReAppTotem: "",
		idReAppTotem: "",
		bookingSubmitted: null,
		bookingByNotificationSubmitted: null,
		tenantsFilter: [],
		roomTypeFilter: [],
		pageAsGoToHome: null,
		municipalityFilter: null,
		spidResponse: null,
		totemLocation: null,
		userLoggedData: null
	}) as State,

	getters: {
		tenantConfig(state) {
			if (state.tenantSelected) {
				if (state.config) {
					const c = state.config.find(function (item: { tenantId: any; }) {
						return item.tenantId == state.tenantSelected.id;
					});
					if (c) return c;
				}
			}

			return null;
		}
	},

	actions: {
		clearHistorySteps() {
			this.stepsMade = {
				municipality: null,
				location: null,
				service: null,
				month: null,
				day: null,
				interval: null,
				spid: null,
				details: null
			};

			this.proStepsMade = {
				location: null,
				categories: [],
				services: [],
				month: null,
				day: null,
				interval: null,
				municipality: null
			};

			this.bookingSubmitted = null;
			this.bookingByNotificationSubmitted = null;
		}
	}
})
