import { computed, ref } from "vue";
import { useMediaQuery } from "@vueuse/core";
import { Config } from "@/models/Config";
import { defaultLocale } from "@/localization";

const language = ref("");
const labelCompany = ref("");
const labelPro = ref("");
const toolbarTitle = ref(null);
const recaptchaClientKey = ref("");
const baseUrl = ref(null);
const modeChoice = ref(null);
const baseImagePath = ref(null);
const rootElement = ref("");
const redirectUrlData = ref(null);
const userLoggedData = ref(null);

// eslint-disable-next-line
export function useWidget() {
    const loading = ref(false);

    const setupApp = async (el: string, cfg: Config) => {
        if (cfg != null) {
            rootElement.value = el;

            if (cfg.language && cfg.language != "") {
                language.value = cfg.language;
            } else {
                language.value = defaultLocale;
            }

            if (cfg.labelCompany) {
                labelCompany.value = cfg.labelCompany;
            }

            if (cfg.labelPro) {
                labelPro.value = cfg.labelPro;
            }

            if (cfg.toolbarTitle) {
                toolbarTitle.value = cfg.toolbarTitle;
            }

            if (cfg.recaptchaClientKey) {
                recaptchaClientKey.value = cfg.recaptchaClientKey;
            }

            if (cfg.baseUrl) {
                baseUrl.value = cfg.baseUrl;
            }

            if (cfg.modeChoice) {
                modeChoice.value = cfg.modeChoice;
            }

            if (cfg.redirectUrlData) {
                redirectUrlData.value = cfg.redirectUrlData;
            }

            if (cfg.userLoggedData) {
                userLoggedData.value = cfg.userLoggedData;
            }
        } else {
            throw new Error("Widget inizialization failed due to a wrong configuration object");
        }
    };

    const _displayError = (el: Element, error: string) => {
        if (el != null) {
            el.innerHTML = `<div style='margin: 0 auto; color: red; padding: 20px; margin: 20px;background-color: white'>${error}</div>`;
        }
    };

    const _displayInfo = (el: Element, info: string) => {
        if (el != null) {
            el.innerHTML = `<div style='margin: 0 auto; color: lightblue; padding: 20px; margin: 20px;background-color: white'>${info}</div>`;
        }
    };

    const isLargeScreen = useMediaQuery("(min-width: 960px)");

    const isTryAndBuyMode = computed(() => {
        if (modeChoice.value && modeChoice.value === "TryAndBuy") {
            return true;
        } else return false;
    });

    return {
        loading,
        rootElement,
        isLargeScreen,
        language,
        labelCompany,
        labelPro,
        toolbarTitle,
        recaptchaClientKey,
        baseUrl,
        modeChoice,
        redirectUrlData,
        userLoggedData,
        baseImagePath,
        isTryAndBuyMode,
        setupApp,
        _displayInfo,
        _displayError
    };
}
