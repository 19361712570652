import { BaseStepMadeModel } from './BaseStepMadeModel';

export class MonthAvailable extends BaseStepMadeModel{
	constructor() {
		super("month");
		this.month = 0;
		this.monthName = "";
		this.year = 0;
	}

	month: number;
	year: number;
	monthName: string;
}
