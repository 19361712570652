import { BaseService } from "./BaseService";
import { AxiosError, AxiosResponse } from "axios";
import { TenantItem } from "@/models/Tenant/TenantItem";
import { ConfigurationResponse } from "@/models/Configuration/ConfigurationResponse";
import _ from "lodash";
import { StoreConfigurationModel } from "@/models/Configuration/StoreConfigurationModel";
import { ConfigurationModel } from "@/models/Configuration/GenericConfigurationModel";
import { useStore } from '../models/Store/store'

class TenantService extends BaseService {

  getTenants(tenantsToFilter: string[], tenantsByAlias = false): Promise<AxiosResponse<TenantItem[]>> {
    const params = {
      tenantsToFilter: tenantsToFilter
    };
    const baseEndpoint = tenantsByAlias ? "v1/tenantsByAlias" : "v1/tenants";
    const qs = this.toQueryString(params);
    return new Promise((resolve, reject) => {
      this.api
        .get<TenantItem[]>(`${baseEndpoint}?${qs}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getConfigurations(tenantId: number, comune: string[], roomType: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const params = {
        comune: comune,
        roomType: roomType
      };
      const qs = this.toQueryString(params);

      this.api
        .get<ConfigurationResponse>(`v1/configurations${tenantId > 0 ? "?tenantId=" + tenantId : ""}${(comune && comune.length > 0) || (roomType && roomType.length > 0) ? "&" + qs : ""}`)
        .then((result) => {
          console.log("GetConfiguration");
          console.log(result);
          if (_.isNull(result) === false && _.isUndefined(result) === false) {
            if (_.isNull(result.data) === false && _.isUndefined(result.data) === false) {
              const tenantsConfig: StoreConfigurationModel[] = [];

              result.data.tenantConfigurations.forEach((cc) => {
                const genericConfig: any = {
                  email: [],
                  sms: [],
                  tenant: [],
                  totem: []
                };

                cc.configuration.forEach((obj) => {
                  if (genericConfig[obj.groupCode]) {
                    genericConfig[obj.groupCode].push(obj);
                  }
                });

                tenantsConfig.push({
                  bookingFlow: cc.bookingFlow,
                  configurations: genericConfig as ConfigurationModel,
                  privacyPolicies: cc.privacyPolicies,
                  tenantId: cc.tenantId
                });
              });

              const store = useStore();
              console.log("Store");
              console.log(tenantsConfig);
              store.config = tenantsConfig;
              resolve();
            }
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }
}

export default new TenantService();