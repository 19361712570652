import { LocationScheduleItem } from '../Location/LocationScheduleItem';


export class RoomLocationResponse {
	constructor() {
		this.address = "",
			this.id = "",
			this.name = "",
			this.city = "",
			this.schedules = [],
			this.roomType = 0;
	}
	id: string;
	name: string;
	roomType: number;
	city: string;
	address: string;
	schedules: LocationScheduleItem[];
}