export class BaseStepMadeModel {
	constructor(origin: string) {
		this.id = "";
		this.name = "";
		this.origin = origin;
	}

	id: string;
	name: string;
	origin: string;
}
