import moment from "moment";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const showHistoryMobilePanel = ref(false);

// eslint-disable-next-line 
export function useToolbar() {
  const route = useRoute();
  const router = useRouter();

  const currentDate = ref(null);
  const currentTime = ref(null);
  const stepToBack = ref(-1);
  const homeRoute = ref({ name: "tenant" });

  const showBackButtonWeb = computed(() => {
    const hasTenantFilter = route.query.tenant;
    const hasComuneFilter = route.query.comune;
    if (hasTenantFilter || hasComuneFilter) {
      return false;
    }
    return true;
  });

  const isStartPage = computed(() => {
    return route.path === "/web" ||
      route.path === "/web/" ||
      route.path === "/totem" ||
      route.path === "/totem/" ||
      route.path === "/totem/tenantchoice" ||
      route.path === "/web/tenantchoice";
  });

  const isSummaryPage = computed(() => {
    return route.path === "/totem/summary" || route.path === "/web/summary";
  });

  const clock = () => {
    // const localeCode = t("settings.localeCode");

    setInterval(() => {
      const tempDate = moment();
      //tempDate.locale(localeCode);
      currentDate.value = tempDate.format("DD MMMM YYYY").toString().toUpperCase();
      currentTime.value = tempDate.format("LT");
    }, 1000);
  }

  const updateHomeRoute = (newHomeRoute: any) => {
    homeRoute.value = newHomeRoute;
  }

  const goToHome = () => {
    router.push(homeRoute.value);
  }

  const changeStepToback = (step: number) => {
    stepToBack.value = step;
  }

  return {
    showBackButtonWeb, showHistoryMobilePanel, currentDate, currentTime, stepToBack, isStartPage, isSummaryPage, homeRoute,
    goToHome, updateHomeRoute, changeStepToback, clock
  }
}