import { ConfigurationItem } from '../Configuration/ConfigurationItem';

class Utils {
	public getGenericConfig(globalConfig: ConfigurationItem[]): any {
		const config: any = {};
		globalConfig.forEach(obj => {
			if (obj.value == 'true') { 
				obj.value = true;
			} else if (obj.value == 'false') {
				obj.value = false;
			}

			config[this.camelCase(obj.varName)] = obj;
		});

		return config;
	}

	public camelCase(input: string) { 
		//return input.toLowerCase().replace(/[\.-_](.)/g, function (match, group) {
		return input.toLowerCase().replace(/[.-_](.)/g, function (match, group) {
			return group.toUpperCase();
		});
	}
}

export default new Utils();