import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import { useWidget } from "./composables/useWidget";
import { languages } from "./localization";
import { Config } from "./models/Config";
import router from "./router-web"
import mitt from 'mitt';
import kappa from '@k-digitale/kappa'

import '@/assets/saas/datepicker.scss'
import "@/assets/css/base.css";
import "@/assets/css/base.css";
import "@/assets/saas/customBase.scss";

const currentScriptSrc = (document.currentScript as HTMLScriptElement).src;

// eslint-disable-next-line
(window as any).initREWidget = function initREWidget(el: string, config: Config = null) {

    const widgetContainer = document.querySelector(el);
    // const currentScriptUrl = new URL(currentScriptSrc);

    if (widgetContainer && widgetContainer.innerHTML.trim().length == 0) {
        widgetContainer.innerHTML = `<div style='color: lightgrey; padding: 20px; background-color: white; border: 1px solid gainsboro; text-align: center;'>Loading widget..</div>`;
    }

    const { setupApp, language } = useWidget();
    const pinia = createPinia();
    const emitter = mitt();

    setupApp(el, config)
        .then(() => {
            const messages = Object.assign(languages)
            const i18n = createI18n({
                legacy: false,
                locale: language.value,
                fallbackLocale: 'it',
                messages
            });

            createApp(App)
                .use(kappa)
                .use(router)
                .use(i18n)
                .use(pinia)
                .provide('emitter', emitter)
                .mount(el)
        })
        .catch(err => {
            console.error("Error initializing booking widget: " + err);
            if (widgetContainer) widgetContainer.innerHTML = `<div style='color: indianred; padding: 20px; background-color: white; border: 1px solid gainsboro; text-align: center;'>Widget initialization error!</div>`;
        });
}