import { BaseStepMadeModel } from './BaseStepMadeModel';

export class DayAvailable extends BaseStepMadeModel {
	constructor() {
		super("day");
		this.day = 0;
		this.dayName = ""
	}

	day: number;
	dayName: string;
}
