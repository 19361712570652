import axios, { AxiosInstance } from 'axios';
import { ApiResponse } from './responses/ApiResponse';
import { useWidget } from "../composables/useWidget";

const { baseUrl } = useWidget();

let _api: AxiosInstance;

export class BaseService {

    toQueryString(params: any) {
        const queryString = Object.keys(params)
            .map(function (key) {
                if (Array.isArray(params[key])) {
                    return Object.keys(params[key])
                        .map(function (k, i) {
                            return `${key}[${i}]=${params[key][i]}`;
                        })
                        .join("&");
                } else {
                    return key + "=" + params[key];
                }
            })
            .join("&");

        return queryString;
    }

    get api() {
        if (!_api) {
            _api = axios.create({
                baseURL: baseUrl.value,
                headers: { "Content-type": "application/json" }
            });
        }
        return _api
    }

    handleApiResponse<T>(response: ApiResponse<T>): T {
        if (!response.success) {
            //Toastr.warning("Errore nella richiesta al server.", response.messages);
            // TODO
            console.warn("Server response valid but request unsuccessful.." + JSON.stringify(response.messages));
        }

        return response.data;
    }

    handleWEApiResponse<T>(response: ApiResponse<T>): T {
        if (response.errorCode != 0) {
            //Toastr.warning("Errore nella richiesta al server.", response.messages);
            // TODO
            console.warn("Server response valid but request unsuccessful.." + JSON.stringify(response.messages));
        }
        console.log('response:', response);
        return response.result.result ? response.result.result : response.result.items;
    }

    tryExecute = async <T>(func: Promise<T>): Promise<T | null> => {
        try {
            const result = await func;
            return result;
        }
        catch (err) {
            console.error("Server communication error.. " + err);
            return null;
        }
    }
}