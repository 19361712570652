import { BaseService } from "./BaseService";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { MunicipalityResponse } from "@/models/Responses/MunicipalityResponse";
import { RoomResponse } from "@/models/Responses/RoomResponse";
import { MonthAvailableResponse } from "@/models/Responses/MonthAvailableResponse";
import { DayAvailableResponse } from "@/models/Responses/DayAvailableResponse";
import { IntervalsAvailableResponse } from "@/models/Responses/IntervalsAvailableResponse";
import { FindBookToEditViewModel } from "@/models/Appointment/FindBookToEditViewModel";
import { AppointmentSearchResponse } from "@/models/Responses/AppointmentSearchResponse";
import { ErrorResponseItem } from "@/models/Error/ErrorResponseItem";
import { CheckinRequestItem } from "@/models/Appointment/CheckinRequestItem";
import { ServiceItem } from "@/models/Service/ServiceItem";
import { DateAvailableResponse } from "@/models/Responses/DateAvailableResponse";
import { MultipleSlotAvailableResponse } from "@/models/Calendar/MultipleSlotAvailableResponse";
import { AppointmentResultResponse } from "@/models/Responses/AppointmentResultResponse";
import { AppointmentByNotificationResponse } from "@/models/Responses/AppointmentByNotificationResponse";

class BookingService extends BaseService {
  getMunicipality(tenantId: number, serviceIds: number[]): Promise<AxiosResponse<MunicipalityResponse>> {
    const params = {
      serviceIds: serviceIds
    };

    const qs = this.toQueryString(params);
    return new Promise((resolve, reject) => {
      this.api
        .get<MunicipalityResponse>(`v1/municipality/${tenantId}?${qs}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getLocations(tenantId: number, serviceIds: number[], comune: string[], roomtype: string[]): Promise<AxiosResponse<RoomResponse[]>> {
    const params = {
      serviceIds: serviceIds,
      comune: comune,
      roomtype: roomtype
    };
    return new Promise((resolve, reject) => {
      const qs = this.toQueryString(params);
      this.api
        .get<RoomResponse[]>(`v1/rooms/${tenantId}?${qs}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getAvailableMonths(tenantId: number, locationId: string, serviceId: number, delayDays: number, bookingSourceCode: string, bookingCode: string): Promise<AxiosResponse<MonthAvailableResponse>> {
    let url = `v2/months/${tenantId}/${locationId}/${serviceId}/${delayDays}/${bookingSourceCode}`;
    if (bookingCode && bookingCode !== "") {
      url = url + `?bookingCode=${bookingCode}`;
    }
    return new Promise((resolve, reject) => {
      this.api
        .get<MonthAvailableResponse>(url)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getAvailableDays(
    tenantId: number,
    locationId: string,
    serviceId: number,
    delayDays: number,
    year: number,
    month: number,
    bookingSourceCode: string,
    bookingCode: string
  ): Promise<AxiosResponse<DayAvailableResponse>> {
    let url = `v2/days/${tenantId}/${locationId}/${serviceId}/${year}/${month}/${delayDays}/${bookingSourceCode}`;
    if (bookingCode && bookingCode !== "") {
      url = url + `?bookingCode=${bookingCode}`;
    }
    return new Promise((resolve, reject) => {
      this.api
        .get<DayAvailableResponse>(url)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getAvailableIntervals(url: string): Promise<AxiosResponse<IntervalsAvailableResponse>> {
    return new Promise((resolve, reject) => {
      this.api
        .get<IntervalsAvailableResponse>(url)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getBookingToEdit(data: FindBookToEditViewModel): Promise<AxiosResponse<AppointmentSearchResponse>> {
    return new Promise((resolve, reject) => {
      this.api
        .post<AppointmentSearchResponse>("v1/appointment/findbooktoedit", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getBookingInfo(keycode: string, hashCode: string): Promise<AxiosResponse<AppointmentSearchResponse>> {
    return new Promise((resolve, reject) => {
      this.api
        .get<AppointmentSearchResponse>(`v1/appointment/bookinginfo/${keycode}?hashCode=${hashCode}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  doCheckin(data: CheckinRequestItem): Promise<AxiosResponse<ErrorResponseItem>> {
    return new Promise((resolve, reject) => {
      this.api
        .post<ErrorResponseItem>("v1/appointment/externalcheckin", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getServices(tenantId: number, comune: string[], roomType: string[]): Promise<AxiosResponse<ServiceItem[]>> {
    const params = {
      comune: comune,
      roomtype: roomType
    };
    const qs = this.toQueryString(params);
    return new Promise((resolve, reject) => {
      this.api
        .get<ServiceItem[]>(`v1/services/${tenantId}?${(comune && comune.length > 0) || (roomType && roomType.length > 0) ? "&" + qs : ""}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getService(serviceId: number): Promise<AxiosResponse<ServiceItem>> {
    return new Promise((resolve, reject) => {
      this.api
        .get<ServiceItem>(`v1/service/${serviceId}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getNextDaysAvailability(tenantId: number, serviceId: any, locationId: string, delayDays: number, bookingSourceCode: string, bookingCode: string): Promise<AxiosResponse<DateAvailableResponse>> {
    let url = `v2/availability/xdays/${tenantId}/${locationId}/${serviceId}/${delayDays}/${bookingSourceCode}`;
    if (bookingCode && bookingCode !== "") {
      url = url + `&bookingCode=${bookingCode}`;
    }
    return new Promise((resolve, reject) => {
      this.api
        .get<DateAvailableResponse>(url)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getAvailableDatesContactCenter(
    tenantId: number,
    services: any[],
    locationId: string,
    selectedDate: any,
    delayDays: number,
    bookingCode: string,
    bookingSourceCode: string
  ): Promise<AxiosResponse<MultipleSlotAvailableResponse[]>> {
    let url = `v2/availability/multiple/${tenantId}/${locationId}/${delayDays}/${bookingSourceCode}?selectedDate=${selectedDate}`;
    if (bookingCode && bookingCode !== "") {
      url = url + `&bookingCode=${bookingCode}`;
    }
    return new Promise((resolve, reject) => {
      this.api
        .post<MultipleSlotAvailableResponse[]>(url, services)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getNextDaysAvailabilityPro(
    tenantId: number,
    serviceIds: any,
    locationId: string,
    delayDays: number,
    bookingSourceCode: string,
    bookingCode: string
  ): Promise<AxiosResponse<DateAvailableResponse>> {
    let url = `v2/availability/multiple/xdays/${tenantId}/${locationId}/${delayDays}/${bookingSourceCode}`;
    if (bookingCode && bookingCode !== "") {
      url = url + `?bookingCode=${bookingCode}`;
    }
    return new Promise((resolve, reject) => {
      this.api
        .post<DateAvailableResponse>(url, serviceIds)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getAvailableHoursPro(tenantId: number, data: any, locationId: string, bookingSourceCode: string): Promise<AxiosResponse<IntervalsAvailableResponse>> {
    return new Promise((resolve, reject) => {
      this.api
        .post<IntervalsAvailableResponse>(`v2/hours/multiple/${tenantId}/${locationId}/${bookingSourceCode}`, data)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getAvailableMonthsPro(tenantId: number, services: any[], locationId: string, delayDays: number, bookingSourceCode: string, bookingCode: string): Promise<AxiosResponse<MonthAvailableResponse>> {
    let url = `v2/months/multiple/${tenantId}/${locationId}/${delayDays}/${bookingSourceCode}`;
    if (bookingCode && bookingCode !== "") {
      url = url + `?bookingCode=${bookingCode}`;
    }
    return new Promise((resolve, reject) => {
      this.api
        .post<MonthAvailableResponse>(url, services)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getAvailableDaysPro(
    tenantId: number,
    locationId: string,
    services: any[],
    delayDays: number,
    year: number,
    month: number,
    bookingSourceCode: string,
    bookingCode: string
  ): Promise<AxiosResponse<DayAvailableResponse>> {
    let url = `v2/days/multiple/${tenantId}/${locationId}/${year}/${month}/${delayDays}/${bookingSourceCode}`;
    if (bookingCode && bookingCode !== "") {
      url = url + `?bookingCode=${bookingCode}`;
    }
    return new Promise((resolve, reject) => {
      this.api
        .post<DayAvailableResponse>(url, services)
        .then((result) => {
          resolve(result);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  }

  deleteBooking(code: string, keycode: string, source?: string): Promise<AxiosResponse<boolean>> {
    return new Promise((resolve, reject) => {
      this.api
        .delete(`v1/appointment/${code}?keycode=${keycode}${source ? "&source=" + source : ""}`)
        .then((result) => resolve(result))
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  submitProBook(data: any, idBookingToUpdate?: string): Promise<AppointmentResultResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .post<AppointmentResultResponse>(`v1/appointment/expbook${idBookingToUpdate ? "?bookingId=" + idBookingToUpdate : ""}`, data)
        .then((res) => {
          if (res && res.data) {
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  }

  submitBook(data: any, idBookingToUpdate?: string): Promise<AppointmentResultResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .post<AppointmentResultResponse>(`v1/appointment/book${idBookingToUpdate ? "?bookingId=" + idBookingToUpdate : ""}`, data)
        .then((res) => {
          if (res && res.data) {
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((err: AxiosError) => {
          reject(err);
          console.log(err);
        });
    });
  }

  submitRecall(data: any): Promise<AppointmentByNotificationResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .post<AppointmentByNotificationResponse>(`v1/appointment/bookrecall`, data)
        .then((res) => {
          if (res && res.data) {
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((err: AxiosError) => {
          reject(err);
          console.log(err);
        });
    });
  }
}

export default new BookingService();