import _ from "lodash";
/* eslint-disable */
export function useFormValidation() {

  const validateEmail = (text: string) => {
    // prettier-ignore
    //const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;    
    //const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //Questa regex ha problemi di BACKTRACKING nella validazione stessa
    const re = /^\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(?:\.\w{2,3})+$/;
    return re.test(text);
  };

  const validatePhoneNumber = (text: string) => {
    // prettier-ignore
    const re = /^[3]{1}[0-9]{8,9}$/;
    const reult = re.test(text);
    return reult;
  };

  const validateTaxCodeOrPIVA = (text: string) => {
    // prettier-ignore
    const reIva = /^[0-9]{11}$/;
    const reCf = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;
    return reIva.test(text) || reCf.test(text);
  };

  const validateText = (text: string) => {
    // prettier-ignore
    const reult = !_.isNull(text) && !_.isEmpty(text);
    return reult;
  };

  return {
    validateEmail, validatePhoneNumber, validateTaxCodeOrPIVA, validateText
  }
}