import { BaseStepMadeModel } from "./BaseStepMadeModel";

export class LocationModel extends BaseStepMadeModel {
	constructor() {
		super("location");
		this.roomType = 0;
	}
	roomType: number;
	city: string;
	address: string;
	hours: string;
}
